import React, { Component } from 'react';
import { NavLink, HashRouter, Routes, Route } from "react-router-dom";
import './App.scss';
import logo from './res/logo-112x112.png';
import LoginForm from './comps/loginForm/loginForm';
import api from './api.js';
import AlertPanel from './comps/alertPanel/alertPanel.js';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'
//locals to import
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/he';

import { FaServer, FaCogs, FaUserCog } from 'react-icons/fa';
import { IoPowerSharp } from 'react-icons/io5';
import PbxTable from './comps/pbxTable/pbxTable';
import Settings from './comps/settings/settings.js';
import Users from './comps/users/users.js';

import { _locSetLanguage, _loc } from './i18n/i18n.js';



const reloadPage = () => {
  //We reload the complete page
  const { protocol, host } = window.location;
  const url = `${protocol}//${host}`;
  console.warn(`User disconnected. Reloading ${url}`);
  window.location.href = url;
}

class App extends Component {
  constructor(props) {
    super(props);

    //Set a default language.
    _locSetLanguage("en_US");
    moment.locale("en_US");

    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      api.initApi(`http://127.0.0.1/VSCode/RAdmin/v2/server/api`, () => {
        reloadPage();
      });
    }
    else {
      api.initApi(`${window.location.protocol}//${window.location.host}/api`, () => {
        reloadPage();
      });
    }

    this.state = {
      user: null,
      hasLicense: null,
      hasBackupOption: false,
      news: null
    }

    this.connectedStateTimer = null;
  }

  componentDidMount() {
    this.loadInitialDataAsync();
  }

  componentWillUnmount() {
    clearInterval(this.connectedStateTimer);
  }


  async loadInitialDataAsync() {
    const { users } = api;
    const hasLicense = await api.hasLicenseAsync("7236");
    const hasBackupOption = await api.hasLicenseAsync("7237");
    const res = await users.connectedAsync();
    if (res) {
      await this.loadDiskUsageAsync();
    }
    this.setState({
      user: res,
      hasLicense,
      hasBackupOption
    });
  }


  async onLoginAsync(user, lang) {
    this.setState({
      user
    });

    await this.loadDiskUsageAsync();

    //Constantly check connection
    this.connectedStateTimer = setInterval(async () => {
      await api.users.connectedAsync();
    }, 60000);

    //Load news
    const news = await api.news.getActiveNews(lang);
    this.setState({
      news
    });
  }

  async logoutAsync() {
    const { users } = api;
    const res = await users.logoutAsync();
    if (res) {
      this.setState({
        user: null
      });
    }
  }

  async loadDiskUsageAsync() {
    const diskFreeSpace = await api.utils.diskFreeSpaceAsync();
    if (diskFreeSpace.percentFree < 10) {
      console.error("Disk usage", diskFreeSpace);
      alert(_loc("diskUsageWarning"));
    }
    console.info("Disk usage", diskFreeSpace);
  }

  buildNextNews() {
    const { news } = this.state;

    if (news !== null && news.length > 0) {
      const n = news[0];
      return <div>
        <h2>{n.title}</h2>
        <div>
          <ReactMarkdown children={n.content} />
          <div className='buttons'>
            <button className='bg-info' onClick={() => { this.closeNewsAsync(false) }}>{_loc("showMeLater")}</button>
            <button className='bg-success' onClick={() => { this.closeNewsAsync(true) }}>{_loc("close")}</button>
          </div>
        </div>
      </div>
    }
    return null;
  }


  async closeNewsAsync(discard) {
    const { news } = this.state;
    const curNews = news[0];
    if (discard) {
      await api.news.discardNews(curNews.id);
    }
    this.setState({
      news: news.slice(1)
    });
  }


  render() {
    const { user, hasLicense, hasBackupOption } = this.state;


    if (hasLicense === null) {
      return null;
    }

    if (!hasLicense) {
      return <div className='App'><AlertPanel message={`No license found`} type="error" top="10px" timeout={0} onTimeour={() => { }} /></div >
    }

    if (user === null) {
      return <div className='App'><LoginForm api={api} onLogin={(user, lang) => { this.onLoginAsync(user, lang) }} /></div>
    }

    const nextNews = this.buildNextNews();


    return (
      <HashRouter>
        <div className="App">
          <div className='app-main'>
            <div className='app-menu-bar'>
              <div className='app-logo'>
                <img src={logo} alt="RA" />
              </div>
              <nav className='menu-items'>
                <NavLink className={({ isActive }) => isActive ? "menu-item selected" : "menu-item"} to="/ipbxs"><FaServer /></NavLink>
                <NavLink className={({ isActive }) => isActive ? "menu-item selected" : "menu-item"} to="/settings"><FaCogs /></NavLink>
                <NavLink className={({ isActive }) => isActive ? "menu-item selected" : "menu-item"} to="/users"><FaUserCog /></NavLink>
                <div className="menu-item" onClick={() => { this.logoutAsync() }}><IoPowerSharp /></div>
              </nav>
            </div>
            <div className='app-pages'>
              <Routes>
                <Route path="/settings" element={<Settings api={api} />} />
                <Route path="/users" element={<Users api={api} />} />
                <Route path="*" element={<PbxTable api={api} hasBackupOption={hasBackupOption} />} />
              </Routes>
            </div>
            {
              nextNews
                ? <div className='news-div'>
                  {nextNews}
                </div>
                : null
            }
          </div>
        </div>
      </HashRouter>
    );
  }

}

export default App;
