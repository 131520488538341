import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class AlertPanel extends Component {
  componentDidMount() {
    const { timeout, onTimeout } = this.props;

    if (timeout > 0) {
      setTimeout(() => {
        onTimeout();
      }, timeout)
    }
  }

  render() {
    const { message, type, top, timeout } = this.props;
    var typeClass = type !== undefined ? `type-${type}` : "type-info";

    return (
      <div className='alert-panel' style={{
        animation: timeout > 0 ? `fadeOut ${timeout + 100}ms linear normal` : '',
        top: top
      }}>
        <div className={`alert-message ${typeClass}`}>
          {message}
        </div>
      </div>
    );
  }
}

export default AlertPanel;
AlertPanel.propTypes = {
  message: PropTypes.string.isRequired,
  timeout: PropTypes.number.isRequired,
  onTimeout: PropTypes.func.isRequired,
  type: PropTypes.string,
  top: PropTypes.string
}
