import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './powerTable.scss';
import PowerTableColumn from './PowerTableColumn';
import writeXlsxFile from 'write-excel-file'
import { TbSortAscending2, TbSortDescending2, TbColumnsOff, TbDeviceFloppy } from 'react-icons/tb';

class PowerTable extends Component {

  #tableSettingsAutoHideTimer = null;
  #powerTableDataObject = null;
  constructor(props) {
    super(props);

    let sortBy = this.props.columns.length > 0 ? this.props.columns[0].id : null;
    let sortType = 'asc';
    let hiddenColumns = [];
    this.#powerTableDataObject = {};
    if (this.props.saveSettings) {
      this.#powerTableDataObject = localStorage.getItem(`PowerTable_${this.props.id}`) ? JSON.parse(localStorage.getItem(`PowerTable_${this.props.id}`)) : {};
      console.log(`Loaded powertable ${this.props.id} settings: `, this.#powerTableDataObject);
      sortBy = this.#powerTableDataObject?.settings?.sortBy ? this.#powerTableDataObject.settings.sortBy : sortBy;
      sortType = this.#powerTableDataObject?.settings?.sortType ? this.#powerTableDataObject.settings.sortType : sortType;
      hiddenColumns = this.#powerTableDataObject?.settings?.hiddenColumns ? this.#powerTableDataObject.settings.hiddenColumns : [];
    }

    this.state = {
      rows: [], //what we will display
      tableID: this.props.id,
      sortBy,
      sortType,
      hiddenColumns,
      showTableSettings: false,
    };


    this.tableRef = React.createRef();

    //check if all column id are uniques. if not, throw error !
    for (let col of this.props.columns) {
      for (let col2 of this.props.columns) {
        if (col !== col2 && col.id === col2.id) {
          throw new Error("Error: PowerTableColumn ID must be unique ! Duplicated value: " + col.id);
        }
      }
    }
  }


  componentDidMount() {
    const { data } = this.props;
    this.#buildRows(data);
  }


  shouldComponentUpdate(nextProps, nextstate) {
    if (nextProps.data !== this.props.data) {
      this.#buildRows(nextProps.data);
    }
    return true;
  }

  #downloadFromBuffer(buffer, fileName = "doc.xls") {
    const blob = new Blob([buffer]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  #saveSettings() {
    const { id } = this.props;
    const { sortBy, sortType, hiddenColumns } = this.state;
    if (this.props.saveSettings && this.#powerTableDataObject) {
      this.#powerTableDataObject.settings = {
        sortBy,
        sortType,
        hiddenColumns
      }
      localStorage.setItem(`PowerTable_${id}`, JSON.stringify(this.#powerTableDataObject))
    }
  }


  async exportTableAsync(table) {
    var ths = table.getElementsByTagName("thead")[0].getElementsByTagName("th");
    var trs = table.getElementsByTagName("tbody")[0].getElementsByTagName("tr");

    const xlsHeader = [];
    const xlsRows = [];
    const xlsRowsRaw = [];

    for (var th of ths) {
      xlsHeader.push({
        value: th.textContent,
        fontWeight: 'bold',
        backgroundColor: "#333333",
        color: "#ffffff",
        borderColor: "#333333",
        borderStyle: "thin"
      });
    }
    for (var tr of trs) {
      const tds = tr.getElementsByTagName("td");
      const xr = [];
      const xrRaw = [];
      for (var td of tds) {
        let cellValue = td.textContent;
        let cellValueRaw = td.hasAttribute("data-rawvalue") ? td.getAttribute("data-rawvalue") : td.textContent;
        const cellTypeRaw = !isNaN(parseFloat(cellValue)) && isFinite(cellValue) ? Number : String;
        xr.push({
          type: String,
          value: cellValue,
          backgroundColor: "#ffffff",
          color: "#000000",
          borderColor: "#333333",
          borderStyle: "thin"
        });
        xrRaw.push({
          type: cellTypeRaw,
          value: cellTypeRaw(cellValueRaw),
          backgroundColor: "#ffffff",
          color: "#000000",
          borderColor: "#333333",
          borderStyle: "thin"
        });
      }
      xlsRows.push(xr);
      xlsRowsRaw.push(xrRaw);
    }

    const buffer = await writeXlsxFile([
      [xlsHeader, ...xlsRows],
      [xlsHeader, ...xlsRowsRaw]
    ], {
      sheets: ['Formated export', 'Raw value export'],
      buffer: true
    })
    this.#downloadFromBuffer(buffer, "export.xlsx");
  }


  /**
   * Build initial values
   * 
   * each row is an object with 
   */
  #buildRows(data) {
    const { columns } = this.props;
    const { sortBy, sortType } = this.state;

    return new Promise(async (resolve) => {
      const rows = data.map((dat, index) => {
        const cells = columns.map((col) => {
          const rawValue = this.#calculateRowValue(col, dat);
          const value = col.transformValue && typeof col.transformValue === 'function' ? col.transformValue(dat, rawValue) : rawValue;
          return {
            colID: col.id,
            rawValue,
            value
          };
        })
        return {
          rowID: index,
          cells
        }
      })

      const sortedRows = this.#sortRows(rows, sortBy, sortType);
      this.setState({
        rows: sortedRows
      }, () => {
        resolve();
      });
    })
  }



  /** 
   * sort rows
   * 
   */
  #sortRows(rows, sortBy, sortType) {
    const { columns } = this.props;

    //find the column on which we sort.
    const col = columns.find(c => c.id === sortBy);
    if (!col) {
      console.warn(`Cannot sort data: failed to get column ${sortBy}`);
      return rows;
    }
    if (!sortBy) {
      console.warn(`Cannot sort data: sortBy not set`);
      return rows;
    }

    //sort rows
    const sorted = rows.sort((rowA, rowB) => {
      //We search the cells with the same columnID
      const cellA = rowA.cells.find(c => c.colID === col.id);
      const cellB = rowB.cells.find(c => c.colID === col.id);
      if (sortType === "asc") {
        return col.sort(cellA.rawValue, cellB.rawValue);
      } else {
        return col.sort(cellB.rawValue, cellA.rawValue);
      }
    });


    return sorted
  }




  #calculateRowValue(col, obj) {
    if (!col.valueProperty) {
      return "";
    }
    if (typeof col.valueProperty === 'function') {
      return col.valueProperty(col, obj);
    }
    else if (obj.hasOwnProperty(col.valueProperty)) {
      return obj[col.valueProperty];
    }
    return "";
  }


  renderTableSettings() {
    const { columns } = this.props;
    const { tableID, hiddenColumns } = this.state;

    //position of settings based on settings button
    const buttonPos = document.getElementById(`${tableID}_table-settings`).getBoundingClientRect();
    return (
      <div className='table-settings' style={{
        right: `0px`,
        top: `${buttonPos.top + 5}px`
      }}
        onMouseLeave={() => {
          clearTimeout(this.#tableSettingsAutoHideTimer);
          this.#tableSettingsAutoHideTimer = setTimeout(() => {
            this.setState({
              showTableSettings: false
            })
          }, 3000);
        }}
        onMouseEnter={() => {
          clearTimeout(this.#tableSettingsAutoHideTimer);
        }}
      >
        <ul>
          {
            columns.map((c) => {
              if (c.options.hideable === false) {
                return null;
              }

              return <li>
                <input type="checkbox" key={c.id} checked={hiddenColumns.includes(c.id) === false} onChange={(e) => {
                  if (e.currentTarget.checked) {
                    return this.setState({
                      hiddenColumns: hiddenColumns.filter(id => id !== c.id)
                    }, () => {
                      this.#saveSettings();
                    })
                  }
                  else {
                    return this.setState({
                      hiddenColumns: [...hiddenColumns, c.id]
                    }, () => {
                      this.#saveSettings();
                    })
                  }
                }} /> <label>{c.label}</label>
              </li>
            })
          }
        </ul>
      </div>
    );
  }


  render() {
    const { columns } = this.props;
    const { rows, tableID, sortBy, sortType, showTableSettings, hiddenColumns } = this.state;

    const mustDisplayTableSettings = columns.find(c => c.options.hideable) !== undefined;

    return (
      <div className='powertable'>
        {
          showTableSettings ? this.renderTableSettings() : null
        }

        <div className='buttons'>
          <button className='bg-info' onClick={() => { this.exportTableAsync(this.tableRef.current) }} >
            <TbDeviceFloppy />
          </button>

          {
            mustDisplayTableSettings
              ? <div className='div-table-settings'>
                <button id={`${tableID}_table-settings`} className='bg-info' onClick={() => { this.setState({ showTableSettings: !showTableSettings }) }}><TbColumnsOff /></button>
                {
                  hiddenColumns.length > 0
                    ? <div className='table-settings-count'>
                      {hiddenColumns.length}
                    </div>
                    : null
                }

              </div>
              : null
          }
        </div>
        <div >
          <table id={tableID} ref={this.tableRef}>
            <thead>
              <tr>
                {
                  columns.map((c) => {
                    if (hiddenColumns.includes(c.id)) {
                      return null;
                    }
                    const id = `${tableID}_row_${c.id}`;
                    return <th
                      id={id}
                      key={id}
                      style={{
                        width: c.options.width ? `${c.options.width}px` : "auto",
                        minWidth: c.options.width ? `${c.options.width}px` : undefined,
                      }}
                    >
                      <div className='inner-td'>
                        <label >{c.label}</label>
                        {
                          c.sort
                            ? <label className='sort-buttons'>
                              <TbSortAscending2
                                onClick={() => {
                                  this.setState({
                                    sortBy: c.id,
                                    sortType: 'asc',
                                    rows: this.#sortRows(this.state.rows, c.id, 'asc')
                                  }, () => {
                                    this.#saveSettings();
                                  })
                                }}
                                className={
                                  sortBy === c.id && sortType === 'asc' ? "sorted" : ""
                                }
                              />
                              <TbSortDescending2
                                onClick={() => {
                                  this.setState({
                                    sortBy: c.id,
                                    sortType: 'desc',
                                    rows: this.#sortRows(this.state.rows, c.id, 'desc')
                                  }, () => {
                                    this.#saveSettings();
                                  })
                                }}
                                className={
                                  sortBy === c.id && sortType === 'desc' ? "sorted" : ""
                                }
                              />
                            </label>
                            : null
                        }
                      </div>
                    </th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                rows.map((row) => {
                  return <tr key={row.rowID}>
                    {
                      row.cells.map((c) => {
                        if (hiddenColumns.includes(c.colID)) {
                          return null;
                        }
                        return <td key={`${tableID}_row${row.rowID}_${c.colID}`} data-rawvalue={c.rawValue}>
                          {c.value}
                        </td>
                      })
                    }
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>

    );
  }
}

export default PowerTable;
PowerTable.propTypes = {
  id: PropTypes.string.isRequired, //Must be unique !!!
  columns: PropTypes.arrayOf(PropTypes.instanceOf(PowerTableColumn)).isRequired,
  data: PropTypes.arrayOf(Object),
  saveSettings: PropTypes.bool
}

export {
  PowerTableColumn
}