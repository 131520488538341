
const DefaultColumnOptions = {
  width: null,
  hideable: true
}


class PowerTableColumn {
  id = "";
  valueProperty = null;
  label = "";
  options = {}

  //Pass a function to transform cell data
  transformValue = null;
  //sort function for sorting data
  sort = (a, b) => {
    try {
      const typeA = typeof a;
      const typeB = typeof b;
      if (typeA === typeB) {
        switch (typeA) {
          case "string":
            return a.localeCompare(b);
          case "number":
          case "bigint":
            return a - b;
          case "boolean":
            return Number(a) - Number(b);
          default:
            return 0;
        }
      }
      else {
        console.warn("Comparing two different types", a, b);
        return `${a ? a : ''}`.localeCompare(`${b ? b : ''}`);
      }
    }
    catch (e) {
      console.error(e);
      return 0;
    }
  };
  //What do to when a value is clicked here ?
  onClick = null;


  /**
   * Build a column and tells PowerTable how to extract and display data. 
   * 
   * @param {string} id ID of this colum. this is mandatory and MUST be unique !
   * @param {string|function} valueProperty Property name to extract value from object. If a function is provided, the value is extracted using the function. if null is provided, no value is extracted at all.
   * @param {string} label Label to show (mandatory)
   * @param {Object} options Option object. 
   * @param {function|null} transformValue if function is provided, this callback will be executed with columnd and object as argument, and the result will be displayed.
   * @param {function|boolean|null} sort if function is provided, this function is executed to sort data, if true, the default sort fonction is used, if false or null, sorting this column is disabled.
   * @param {function|null} onClick callback executed when clicking the cell.
   */
  constructor(id, valueProperty, label, options = {}, transformValue = null, sort = true, onClick = null) {
    this.id = id;
    this.valueProperty = valueProperty;
    this.label = label;

    //build options based on defautl and provided.
    this.options = {
      ...DefaultColumnOptions,
      ...options
    }


    if (typeof sort === 'function') {
      this.sort = sort;
    }
    else if (!sort) {
      this.sort = null;
    }

    if (transformValue) {
      this.transformValue = transformValue;
    }
    if (onClick) {
      this.onClick = onClick;
    }
  }


  calculateValue(obj) {
    if (!this.valueProperty) {
      return "";
    }
    if (typeof this.valueProperty === 'function') {
      return this.valueProperty(this, obj);
    }
    else if (obj.hasOwnProperty(this.valueProperty)) {
      return obj[this.valueProperty];
    }
    return "";
  }
}


export default PowerTableColumn
