import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AlertPanel from '../alertPanel/alertPanel';

import { _loc } from '../../i18n/i18n.js';
import moment from 'moment';

import { VscLoading } from 'react-icons/vsc';
import { FiHardDrive } from 'react-icons/fi';
import { FaMemory } from 'react-icons/fa';
import { BsCpu } from 'react-icons/bs';
import { VscSave, VscMail, VscClearAll } from 'react-icons/vsc';
import { MdCancel, MdOutlineSupport } from 'react-icons/md';
import { TbPlugConnectedX, TbExclamationCircle } from 'react-icons/tb';
import { LuDatabaseBackup } from 'react-icons/lu';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      success: null,
      settings: null,
      allowedIPs: null,
      ip: "",
      cleanDays: 365,
      cleanPBXs: null
    }

    this.timerLoading = null;
    this.timerTimeoutIP = null;
  }

  componentDidMount() {
    this.timerTimeoutIP = setInterval(() => {
      const { allowedIPs } = this.state;
      if (allowedIPs !== null) {
        var newAllowedIPs = allowedIPs.map((i) => { return { ...i, timeout: i.timeout - 1 } }).filter(i => i.timeout > 0);
        this.setState({
          allowedIPs: newAllowedIPs
        });
      }
    }, 1000);
    this.timerLoading = setTimeout(async () => {
      await this.loadSettingsAsync();
      await this.loadAllowedIPSAsync();
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timerLoading);
    clearInterval(this.timerTimeoutIP);
  }


  async setLoadingStateAsync(loading) {
    return new Promise((resolve) => {
      this.setState({
        loading
      }, () => {
        resolve();
      })
    });
  }

  async loadAllowedIPSAsync() {
    const { api } = this.props;
    const ips = await api.allowedIPs.getAllowedIPsAsync();
    this.setState({
      allowedIPs: ips
    });
  }

  async loadSettingsAsync() {
    const { api } = this.props;
    const settings = await api.settings.getSettingsAsync();

    this.setState({
      settings,
      loading: false
    });
  }


  async saveSettingsAsync() {
    const { api } = this.props;
    const { settings } = this.state;

    const result1 = await api.settings.setSettingsAsync(settings);

    if (result1 === false) {
      this.setState({
        error: _loc("saveSettingsFailed")
      });
    }
    else {
      this.setState({
        success: _loc("saveSettingsSuccess")
      });
      this.loadSettingsAsync();
    }

  }

  async testMailSettingsAsync() {
    const { api } = this.props;
    const { settings } = this.state;

    await this.setLoadingStateAsync(true);

    if (settings.smtpHost === "" || !settings.smtpPort) {
      this.setState({
        error: _loc("testMailFailed", ["No settings, please edit mail settings, save, and test again"])
      });
      return;
    }

    const result = await api.settings.testMailSettingsAsync(settings.alarmMailTo);
    await this.setLoadingStateAsync(false);
    if (result !== true) {
      this.setState({
        error: _loc("testMailFailed", [result])
      });
    }
    else {
      this.setState({
        success: _loc("testMailSuccess")
      });
    }
  }


  async clearAllAlarmsAsync() {
    const { api } = this.props;
    if (window.confirm(_loc("clearAllAlarmsConfirm"))) {
      if (await api.alarms.clearAllAlarmsAsync(null)) {
        this.setState({
          success: _loc("clearAlarmsSuccess")
        });
      }
      else {
        this.setState({
          error: _loc("clearAlarmsFailed")
        });
      }
    }
  }



  async addAllowedIPAsync() {
    const { api } = this.props;
    const { ip } = this.state;

    await api.allowedIPs.addAllowedIPAsync(ip);
    this.loadAllowedIPSAsync();
    this.setState({ ip: "" });
  }

  async removeAllowedIPAsync(ip) {
    const { api } = this.props;

    await api.allowedIPs.removeAllowedIPAsync(ip);
    this.loadAllowedIPSAsync();
  }


  async cleanIPBXsAsync(force = false) {
    const { api } = this.props;
    const { cleanDays } = this.state;

    if (force) {
      if (window.confirm(_loc("cleanConfirm"))) {
        await this.setLoadingStateAsync(true);
        const deleted = await api.ipbxs.cleanIPBXsAsync(cleanDays);
        await this.setLoadingStateAsync(false);
        this.setState({
          success: _loc("cleanSuccess", [deleted]),
          cleanPBXs: null
        });
      }
      else {
        this.setState({
          cleanPBXs: null
        });
      }

    }
    else {
      await this.setLoadingStateAsync(true);
      const pbxs = await api.ipbxs.cleanIPBXsListAsync(cleanDays);
      await this.setLoadingStateAsync(false);
      this.setState({
        cleanPBXs: pbxs
      });
    }


  }


  render() {
    const { loading, error, success, settings, allowedIPs, ip, cleanDays, cleanPBXs } = this.state;

    if (loading) {
      return <div className='settings'>
        <div className='loading'>
          <VscLoading />
          {_loc("loading")}
        </div>
      </div>
    }

    return (
      <div className='settings'>
        <h1>{_loc("settings")}</h1>

        <div className='form4'>
          <h2>{_loc("alarms")}</h2>
          <p>
            {_loc("alarmInfo")}
          </p>
          <div className='form-row'>
            <label><FiHardDrive /> {_loc("disk")}</label>
            <input type="range" value={settings.alarmDisk} min={0} max={100}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    alarmDisk: parseInt(e.currentTarget.value)
                  }
                })
              }}
            />
            <label>{settings.alarmDisk} %</label>
            <small>
              {_loc("diskInfo")}
            </small>
          </div>


          <div className='form-row'>
            <label><FaMemory /> {_loc("memoryAlarm")}</label>
            <input type="range" value={settings.alarmMemory} min={0} max={100}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    alarmMemory: parseInt(e.currentTarget.value)
                  }
                })
              }}
            />
            <label>{settings.alarmMemory} %</label>
            <small>
              {_loc("memoryInfo")}
            </small>
          </div>


          <div className='form-row'>
            <label><BsCpu /> CPU</label>
            <input type="range" value={settings.alarmCpu} min={1} max={200} step={1}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    alarmCpu: parseInt(e.currentTarget.value)
                  }
                })
              }}
            />
            <label>{settings.alarmCpu} %</label>
            <small>
              {_loc("cpuInfo")}
            </small>
          </div>


          <div className='form-row'>
            <label><TbPlugConnectedX /> Network</label>
            <div>
              <input type="checkbox" checked={settings.alarmDisconnect ? "checked" : ""}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      alarmDisconnect: e.currentTarget.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
            <label></label>
            <small>
              {_loc("networkInfo")}
            </small>
          </div>


          <div className='form-row'>
            <label><LuDatabaseBackup /> Backup</label>
            <div>
              <input type="checkbox" checked={settings.alarmBackup ? "checked" : ""}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      alarmBackup: e.currentTarget.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
            <label></label>
            <small>
              {_loc("alarmBackup")}
            </small>
          </div>


          <div className='form-row'>
            <label><MdOutlineSupport /> Service Agreement</label>
            <div>
              <input type="checkbox" checked={settings.alarmServiceAgreement ? "checked" : ""}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      alarmServiceAgreement: e.currentTarget.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
            <label></label>
            <small>
              {_loc("alarmServiceAgreement")}
            </small>
          </div>


          <div className='form-row'>
            <label><TbExclamationCircle /> Disk Errors</label>
            <div>
              <input type="checkbox" checked={settings.alarmDiskError ? "checked" : ""}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      alarmDiskError: e.currentTarget.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
            <label></label>
            <small>
              {_loc("alarmDiskError")}
            </small>
          </div>
        </div>





        <div className='form4'>
          <div className='form-row'>
            <label>{_loc("sendAlarmByMail")}</label>
            <div>
              <input type="checkbox" checked={settings.sendAlarmByMail === 1}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      sendAlarmByMail: e.target.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
            <div></div>
            <small>
              {_loc("sendAlarmHelp")}
            </small>
          </div>

          <div className='form-row'>
            <label>{_loc("autoclearAlarmBefore")}</label>
            <div>
              <input type="number" value={settings.autoclearAlarmBefore} min={0} max={60}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      autoclearAlarmBefore: e.currentTarget.value
                    }
                  })
                }}
              />
            </div>
            <div>
              {_loc("minutes")}
            </div>
            <small>
              {_loc("autoclearAlarmBeforeHelp")}
            </small>
          </div>

          <div className='form-row'>
            <label>{_loc("sendCPUAlarmAfter")}</label>
            <div>
              <input type="number" value={settings.sendCPUAlarmAfter} min={0} max={60}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      sendCPUAlarmAfter: e.currentTarget.value
                    }
                  })
                }}
              />
            </div>
            <div>
              {_loc("minutes")}
            </div>
            <small>
              {_loc("sendAlarmAfterHelp")}
            </small>
          </div>

          <div className='form-row'>
            <label>{_loc("sendDiskAlarmAfter")}</label>
            <div>
              <input type="number" value={settings.sendDiskAlarmAfter} min={0} max={60}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      sendDiskAlarmAfter: e.currentTarget.value
                    }
                  })
                }}
              />
            </div>
            <div>
              {_loc("minutes")}
            </div>
            <small>
              {_loc("sendAlarmAfterHelp")}
            </small>
          </div>

          <div className='form-row'>
            <label>{_loc("sendMemoryAlarmAfter")}</label>
            <div>
              <input type="number" value={settings.sendMemoryAlarmAfter} min={0} max={60}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      sendMemoryAlarmAfter: e.currentTarget.value
                    }
                  })
                }}
              />
            </div>
            <div>
              {_loc("minutes")}
            </div>
            <small>
              {_loc("sendAlarmAfterHelp")}
            </small>
          </div>

          <div className='form-row'>
            <label>{_loc("sendDisconnectAlarmAfter")}</label>
            <div>
              <input type="number" value={settings.sendDisconnectAlarmAfter} min={0} max={60}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      sendDisconnectAlarmAfter: e.currentTarget.value
                    }
                  })
                }}
              />
            </div>
            <div>
              {_loc("minutes")}
            </div>
            <small>
              {_loc("sendAlarmAfterHelp")}
            </small>
          </div>

          <div className='form-row'>
            <label>{_loc("alarmLanguage")}</label>
            <div>
              <select value={settings.language} onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    language: e.currentTarget.value
                  }
                })
              }}
              >
                {
                  settings.languages.map((l) => {
                    return <option key={l.code} value={l.code}>{l.name}</option>
                  })
                }
              </select>
            </div>
          </div>

          <div className='form-row'>
            <label>{_loc("smtpHost")}</label>
            <input type="text" value={settings.smtpHost}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    smtpHost: e.currentTarget.value
                  }
                })
              }}
            />
          </div>

          <div className='form-row'>
            <label>{_loc("smtpPort")}</label>
            <input type="number" value={settings.smtpPort}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    smtpPort: e.currentTarget.value
                  }
                })
              }}
            />
          </div>

          <div className='form-row'>
            <label>{_loc("smtpTLS")}</label>
            <div>
              <input type="checkbox" checked={settings.smtpTLS === 1}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      smtpTLS: e.target.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
          </div>

          <div className='form-row'>
            <label>{_loc("smtpAuth")}</label>
            <div>
              <input type="checkbox" checked={settings.smtpAuth === 1}
                onChange={(e) => {
                  this.setState({
                    settings: {
                      ...settings,
                      smtpAuth: e.target.checked ? 1 : 0
                    }
                  })
                }}
              />
            </div>
          </div>

          <div className='form-row'>
            <label>{_loc("smtpUsername")}</label>
            <input type="text" value={settings.smtpUsername}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    smtpUsername: e.currentTarget.value
                  }
                })
              }}
            />
          </div>

          <div className='form-row'>
            <label>{_loc("smtpPassword")}</label>
            <input type="password" value={settings.smtpPassword}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    smtpPassword: e.currentTarget.value
                  }
                })
              }}
            />
          </div>

          <div className='form-row'>
            <label>{_loc("mailTo")}</label>
            <input type="text" value={settings.alarmMailTo}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    alarmMailTo: e.currentTarget.value
                  }
                })
              }}
            />
          </div>

          <div className='form-row'>
            <label>{_loc("mailFrom")}</label>
            <input type="text" value={settings.alarmMailFrom}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    alarmMailFrom: e.currentTarget.value
                  }
                })
              }}
            />
          </div>

          <div className='form-row'>
            <label>{_loc("mailSubjectPrefix")}</label>
            <input type="text" value={settings.alarmMailSubjectPrefix}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...settings,
                    alarmMailSubjectPrefix: e.currentTarget.value
                  }
                })
              }}
            />
          </div>
        </div>



        <div className='buttons'>
          <button className='bg-success' onClick={() => { this.saveSettingsAsync() }}>
            <VscSave /> {_loc("save")}
          </button>
          <button className='bg-info' onClick={() => { this.testMailSettingsAsync() }}>
            <VscMail /> {_loc("testMail")}
          </button>
          <button className='bg-danger' onClick={() => { this.clearAllAlarmsAsync() }}>
            <VscMail /> {_loc("clearAllAlarms")}
          </button>
        </div>


        <div className='div-allowedips'>
          <h2>{_loc("autorizedIP")}</h2>
          <table>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>{_loc("ip")}</th>
                <th style={{ width: "200px" }}>{_loc("timeLeft")}</th>
                <th style={{ width: "150px" }}></th>
              </tr>
            </thead>
            <tbody>
              {
                allowedIPs !== null
                  ? allowedIPs.map((i) => {
                    return <tr key={ip}>
                      <td>{i.ip}</td>
                      <td>{i.timeout} s</td>
                      <td><button className='bg-danger' onClick={() => { this.removeAllowedIPAsync(i.ip) }} >{_loc("deleteIP")}</button></td>
                    </tr>
                  })
                  : null
              }
            </tbody>
          </table>
          <small>{_loc("noIP")}</small>
          <div className='form mt-2'>
            <div className='form-row'>
              <label>{_loc("allowIP")} </label>
              <input type="text" value={ip} onChange={(e) => { this.setState({ ip: e.currentTarget.value }) }} />
              <button className='bg-success' onClick={() => { this.addAllowedIPAsync() }}>{_loc("addIP")}</button>
            </div>
          </div>
        </div>



        <div className='div-clean'>
          <h2>{_loc("cleanTitle")}</h2>
          <div className='form4 mt-2'>
            <div className='form-row'>
              <label>{_loc("cleanDays")} </label>
              <input type="number" value={cleanDays} min={1} max={3650} onChange={(e) => { this.setState({ cleanDays: e.currentTarget.value }) }} />
              <div>{_loc("days")}</div>
            </div>
          </div>
          <div className='buttons'>
            <button className='bg-danger' onClick={() => { this.cleanIPBXsAsync(false) }}>
              <VscClearAll />
              {_loc("cleanAction")}
            </button>
          </div>
        </div>


        {
          cleanPBXs !== null
            ? <Fragment>
              <div className='cleanpbx-outer'></div>
              <div className='cleanpbx-confirm'>
                <h2>{_loc("cleanTitle")}</h2>
                <div>
                  {_loc("cleanList")}
                </div>
                <table className='mt-3'>
                  <thead>
                    <tr>
                      <th>{_loc("serial")}</th>
                      <th>{_loc("pbxName")}</th>
                      <th>{_loc("lastConnection")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cleanPBXs.sort((a, b) => { return a.name.localeCompare(b.name) }).map((p) => {
                        return <tr>
                          <td>{p.serial}</td>
                          <td>{p.name}</td>
                          <td>{moment.unix(p.lastConnection).format("DD/MM/YYYY HH:mm:ss")}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
                <div className='buttons mt-3'>
                  <button className='bg-danger' onClick={() => { this.cleanIPBXsAsync(true) }}>
                    <VscClearAll />
                    {_loc("cleanAction")}
                  </button>
                  <button className='bg-warning' onClick={() => { this.setState({ cleanPBXs: null }) }}>
                    <MdCancel />
                    {_loc("cancel")}
                  </button>
                </div>
              </div>
            </Fragment>
            : null
        }

        {
          success !== null
            ? <AlertPanel message={success} timeout={5000} top='10px' onTimeout={() => { this.setState({ success: null }) }} type="success" />
            : null
        }
        {
          error !== null
            ? <AlertPanel message={error} timeout={8000} top='10px' onTimeout={() => { this.setState({ error: null }) }} type="error" />
            : null
        }

      </div>
    );
  }
}

export default Settings;
Settings.propTypes = {
  api: PropTypes.object.isRequired
}