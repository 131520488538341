import * as english from './en_US.json';
import * as french from './fr_FR.json';
import * as spanish from './es_ES.json';

const _languages = {
  en_US: english,
  fr_FR: french,
  es_ES: spanish,
};


//#######################################

var currLang = null;

const _locSetLanguage = (code) => {
  currLang = null;
  if (_languages.hasOwnProperty(code)) {
    currLang = _languages[code];
    console.log(`Language set to ${code}. ${Object.keys(currLang.strings).length} strings loaded`);
  }
  else {
    console.error(`Failed to load language ${code}`);
  }
}


const _locGetCurrentLanguage = () => {
  return currLang.code;
}

const _loc = (code, ...values) => {
  if (currLang !== null && currLang.strings[code.trim()]) {
    var str = currLang.strings[code.trim()];
    for (var val of values) {
      str = str.replace("%s", val);
    }
    return str;
  }
  return "___" + code;
}

const _logGetLanguages = () => {
  var langs = [];
  for (var code in _languages) {
    langs.push({
      code,
      name: _languages[code].name
    });
  }
  return langs;
}


export {
  _loc,
  _locSetLanguage,
  _locGetCurrentLanguage,
  _logGetLanguages
};