import React, { Component } from 'react';
import AlertPanel from '../alertPanel/alertPanel';
import logo from '../../res/logo-330x80.png'
import './style.scss'
import PropTypes from 'prop-types';
import moment from 'moment';

import { _logGetLanguages, _locGetCurrentLanguage, _locSetLanguage, _loc } from '../../i18n/i18n.js';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: _locGetCurrentLanguage(),
      languages: [],
      login: "",
      password: "",
      error: null
    }
  }

  componentDidMount() {
    this.setState({
      languages: _logGetLanguages()
    });
  }


  changeLanguage(code) {
    _locSetLanguage(code);
    moment.locale(code);
    this.setState({
      language: code
    });
  }


  async loginAsync() {
    const { api, onLogin } = this.props;
    const { login, password, language } = this.state;
    var res = await api.users.loginAsync(login, password);
    if (res !== null) {
      onLogin(res.data, language);
    }
    this.setState({
      error: _loc("loginError")
    });
  }

  render() {
    const { languages, language, login, password, error } = this.state;
    return (
      <div className='loginForm'>
        <div className='form'>
          <div className='logo'>
            <img src={logo} alt="RAdmin" />
          </div>
          <div className='form-row'>
            <label>{_loc("language")}</label>
            <select value={language} onChange={(e) => { this.changeLanguage(e.currentTarget.value) }}>
              {
                languages.map(l => <option key={l.code} value={l.code}>{l.name}</option>)
              }
            </select>
          </div>
          <div className='form-row'>
            <label>{_loc("username")}</label>
            <input type="text" value={login} onChange={(e) => { this.setState({ login: e.currentTarget.value }) }} />
          </div>
          <div className='form-row'>
            <label>{_loc("password")}</label>
            <input type="password" value={password} onChange={(e) => { this.setState({ password: e.currentTarget.value }) }} />
          </div>
          <div className='form-row'>
            <label></label>
            <button onClick={() => { this.loginAsync() }} >{_loc("login")}</button>
          </div>
        </div>
        {
          error !== null
            ? <AlertPanel message={error} type="error" top="10px" timeout={20000} onTimeout={() => { this.setState({ error: null }) }} />
            : null
        }
      </div>
    );
  }
}

export default LoginForm;
LoginForm.propTypes = {
  api: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired
}