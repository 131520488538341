import React, { Component, Fragment } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LineChart, Line, ResponsiveContainer, YAxis, XAxis, Tooltip } from 'recharts';
import AlertPanel from '../alertPanel/alertPanel.js';

import { VscLoading, VscRepoPush, VscEdit, VscCopy, VscChromeClose } from 'react-icons/vsc';
import { TbPlugConnectedX, TbPlugConnected } from 'react-icons/tb';
import { MdDone, MdOutlineDoneAll, MdDeleteForever } from 'react-icons/md';
import { IoTrashBinSharp, IoEyeSharp, IoCloseSharp, IoConstructSharp } from 'react-icons/io5';
import { GiSandsOfTime } from 'react-icons/gi';
import PowerTable, { PowerTableColumn } from '../PowerTable/PowerTable.js';

import { _loc } from '../../i18n/i18n.js';


class PbxDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingText: _loc("loading"),
      error: null,
      success: null,
      ipbx: null,
      stats: null,
      statsDuration: 86400,
      commands: null,
      commandToExecute: '',
      notes: '',
      name: '',
      alarmsEnabled: 1,
      commandToShow: null,
      alarms: [],
    }

    this.timerLoading = null;
    this.timerRefreshPBXState = null;
    this.timerRefreshData = null;
    this.timerCommands = null;
  }

  componentDidMount() {
    this.timerLoading = setTimeout(async () => {
      await this.setLoadingAsync(true);
      await this.getPbxAsync();
      await this.getPbxDetailsAsync();
      await this.getCommandsAsync();
      await this.getAlarmsAsync();
      this.timerRefreshPBXState = setTimeout(() => {
        this.updatePBXStateAsync();
      }, 5000);
      this.timerRefreshData = setInterval(async () => {
        await this.getPbxDetailsAsync();
        await this.getAlarmsAsync();
      }, 60000);
      await this.setLoadingAsync(false);
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timerLoading);
    clearTimeout(this.timerCommands);
    clearTimeout(this.timerRefreshPBXState);
    clearTimeout(this.timerRefreshData);
  }

  async setLoadingAsync(loading, loadingText = _loc("loading")) {
    return new Promise((resolve) => {
      this.setState({
        loading,
        loadingText
      }, () => {
        resolve();
      })
    });
  }

  async getPbxAsync() {
    const { api, serial } = this.props;
    var ipbx = await api.ipbxs.getIPBXAsync(serial);
    if (ipbx === null) {
      this.setState({
        error: _loc("pbxNotFound")
      });
    }
    else {
      this.setState({
        ipbx,
        notes: ipbx !== null ? ipbx.notes : '',
        name: ipbx !== null ? ipbx.name : '',
        alarmsEnabled: ipbx !== null ? ipbx.alarmsEnabled : false,
      });
    }
  }


  //Used to update the ipbx object, so we know the current state.
  async updatePBXStateAsync() {
    const { api, serial } = this.props;
    var ipbxUpdated = await api.ipbxs.getIPBXAsync(serial);
    this.setState({
      ipbx: ipbxUpdated
    }, () => {
      this.timerRefreshPBXState = setTimeout(() => {
        this.updatePBXStateAsync();
      }, 5000);
    });
  }

  async getPbxDetailsAsync() {
    const { api, serial } = this.props;
    const { statsDuration } = this.state;
    var stats = await api.stats.getStatsAsync(serial, moment().unix() - statsDuration, moment().unix());
    this.setState({
      stats
    });
  }


  async getCommandsAsync() {
    const { api, serial } = this.props;
    var commands = await api.commands.getCommandsAsync(serial, 10);
    this.setState({
      commands
    }, () => {
      this.timerCommands = setTimeout(() => {
        this.getCommandsAsync();
      }, 5000);
    });
  }

  async getAlarmsAsync() {
    const { api, serial } = this.props;
    var alarms = await api.alarms.getAlarmsAsync(serial);
    this.setState({
      alarms
    });
  }


  async sendCommandAsync() {
    const { api, serial } = this.props;
    const { commandToExecute } = this.state;
    if (commandToExecute !== '' && window.confirm(_loc("remoteExecWarning"))) {
      var res = await api.commands.createCommandAsync(serial, commandToExecute);
      if (res) {
        this.setState({
          commandToExecute: '',
          error: null
        });
        await this.getCommandsAsync();
      }
      else {
        this.setState({
          error: _loc("commandSendError")
        });
      }
    }
  }

  async deleteCommandAsync(id) {
    const { api, serial } = this.props;
    var res = await api.commands.deleteCommandAsync(serial, id);
    if (res) {
      this.setState({
        commandToExecute: '',
        error: null,
        success: _loc("commandDeleted")
      });
      await this.getCommandsAsync();
    }
    else {
      this.setState({
        error: _loc("commandDeleteError")
      });
    }
  }

  async deleteBackupAsync(backup) {
    const { api, serial } = this.props;
    var res = await api.ipbxs.deleteIPBXBackupAsync(serial, backup);
    if (res) {
      this.setState({
        error: null,
        success: _loc("backupDeleted")
      });
    }
    else {
      this.setState({
        error: _loc("backupDeleteError")
      });
    }
    await this.getPbxAsync();
  }

  editName() {
    const { name } = this.state;
    const newName = window.prompt(_loc("renamePBX"), name);
    if (newName !== null && newName !== null && newName.trim() !== "") {
      this.setState({
        name: newName.trim()
      }, () => {
        this.savePBXAsync();
      })
    }
  }

  setAlarmsEnabledAsync(enabled) {
    this.setState({
      alarmsEnabled: enabled
    }, () => {
      this.savePBXAsync();
    });
  }

  async savePBXAsync() {
    const { api, serial } = this.props;
    const { name, notes, alarmsEnabled } = this.state;
    var res = await api.ipbxs.modifyIPBXAsync(serial, name, notes, alarmsEnabled);
    if (res) {
      this.setState({
        error: null,
        success: _loc("pbxSaved")
      });
    }
    else {
      this.setState({
        error: _loc("pbxSaveFailed")
      });
    }
  }


  async deletePBXAsync() {
    const { api, serial, onBack } = this.props;
    const { name } = this.state;
    if (window.confirm(_loc("deleteConfirm", serial, name))) {

      //Do not try to reload data for this PBX as we will delete it. 
      clearTimeout(this.timerLoading);
      clearTimeout(this.timerCommands);
      clearTimeout(this.timerRefreshPBXState);
      clearTimeout(this.timerRefreshData);

      await this.setLoadingAsync(true, _loc("deletingData"));
      var res = await api.ipbxs.deleteIPBXAsync(serial);
      if (res) {
        this.setState({
          error: null,
          success: _loc("pbxDeleted")
        }, () => {
          onBack()
        });
      }
      else {
        this.setLoadingAsync(false);
        this.setState({
          error: _loc("pbxDeleteFailed")
        });
      }
    }
  }

  async downloadBackupAsync(filename) {
    if (window.confirm(_loc("confirmDownload"))) {
      const { api, serial } = this.props;
      const data = await api.ipbxs.getIPBXBackupAsync(serial, filename)
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = filename;
      a.click();
    }
  }

  setStatsDuration(val) {
    this.setState({
      statsDuration: parseInt(val)
    }, async () => {
      await this.setLoadingAsync(true);
      await this.getPbxDetailsAsync();
      this.setLoadingAsync(false);
    });
  }


  async copyCommandResultAsync() {
    const { commandToShow } = this.state;
    try {
      await navigator.clipboard.writeText(commandToShow.result);
      this.setState({
        success: _loc("resultCopied")
      });
    }
    catch (e) {
      this.setState({
        error: _loc("resultCopyFailed", e.message)
      });
    }
  }

  async clearAlarmAsync(id) {
    if (window.confirm(_loc("clearAllWarning", 1))) {
      const { api } = this.props;
      if (await api.alarms.clearAlarmAsync(id)) {
        const { alarms } = this.state;
        this.setState({
          alarms: alarms.map((a) => {
            if (a.id === id) {
              return {
                ...a,
                cleared: moment().unix()
              }
            }
            return { ...a };
          })
        })
      }
      else {
        this.setState({
          error: _loc("clearAlarmFailed", id)
        });
      }
    }
  }


  async clearAllAlarmsAsync() {
    const { api } = this.props;
    const { ipbx, alarms } = this.state;
    if (window.confirm(_loc("clearAllWarning", alarms.filter(a => a.cleared === 0).length))) {
      await api.alarms.clearAllPBXAlarmsAsync(ipbx.serial);
      await this.getAlarmsAsync();
    }
  }




  formatDuration(secondes, forceHours = false) {
    var s = Math.floor(secondes % 60);
    var m = Math.floor((secondes / 60) % 60);
    var h = Math.floor((secondes / 3600) % 60);
    var d = Math.floor((secondes / 86400) % 60);
    var str = d > 0 ? `${d}. ` : "";
    str += forceHours ? `${h < 10 ? "0" : ""}${h}:` : "";
    str += `${m < 10 ? "0" : ""}${m}:`;
    str += `${s < 10 ? "0" : ""}${s}`;
    return str;
  }



  render() {
    const { onBack, hasBackupOption } = this.props;
    const { error, success, loading, loadingText, ipbx, stats, statsDuration, commands, commandToExecute, notes, name, alarmsEnabled, commandToShow, alarms } = this.state;

    if (loading || (ipbx === null || stats === null || commands === null)) {
      return <div className='PbxDetails'>
        <div className='loading'>
          <VscLoading />
          {loadingText}
        </div>
      </div >
    }

    const lastStatDate = stats !== null && stats.length > 0 ? stats[stats.length - 1].date : 0;
    var supportcommand = false;
    if (ipbx.versionRadmin !== "") {
      const tab = ipbx.versionRadmin.split('.');
      if (tab.length === 2) {
        supportcommand = parseInt(tab[0]) >= 1 || (parseInt(tab[0]) === 1 && parseInt(tab[1]) >= 10) ? true : false;
      }
    }

    return (
      <div className='PbxDetails'>
        <div className='header'>
          <div className='status'>
            {
              ipbx.connected
                ? <TbPlugConnected className='text-success state' />
                : <TbPlugConnectedX className='text-danger state' />
            }
          </div>
          <div className='name-serial'>
            <h2>
              {
                ipbx.serial !== name && name !== ''
                  ? `${ipbx.serial} - ${name}`
                  : `${ipbx.serial}`
              }
              <button className='bg-info' onClick={() => { this.editName() }}><VscEdit /></button>
              <button className='bg-danger' onClick={() => { this.deletePBXAsync() }}><IoTrashBinSharp /></button>
            </h2>
            <small>
              {
                ipbx.connected
                  ? _loc("connectedLastData", moment.unix(ipbx.lastStat).format("DD/MM/YYYY HH:mm:ss"))
                  : _loc("notConnected")
              }
              {
                _loc("pbxData",
                  ipbx.version !== "" ? ipbx.version : "?",
                  ipbx.versionRadmin !== "" ? ipbx.versionRadmin : "?",
                  ipbx.publicip !== "" ? ipbx.publicip : "?")
              }
            </small>
          </div>
          <div className='header-buttons'>
            <button className='bg-danger' onClick={() => { onBack() }}><IoCloseSharp /></button>
          </div>
        </div>

        <div className='details-content'>
          <div className='quick-stats'>
            <div className='stats-filter'>
              <label>{_loc("showStatsFrom")} </label>
              <select value={statsDuration} onChange={(e) => { this.setStatsDuration(e.currentTarget.value) }}>
                <option value="3600">{_loc("lastHour")}</option>
                <option value="14400">{_loc("lastFourHours")}</option>
                <option value="28800">{_loc("lastHeightHours")}</option>
                <option value="86400">{_loc("lastDay")}</option>
                <option value="172800">{_loc("twoDays")}</option>
                <option value="345600">{_loc("fourDays")}</option>
                <option value="604800">{_loc("lastWeek")}</option>
                <option value="2678400">{_loc("lastMonth")}</option>
                <option value="8035200">{_loc("threeMonths")}</option>
                <option value="16070400">{_loc("sixMonths")}</option>
              </select>
            </div>

            <div className='stats'>
              <div className='stat-block' style={{ flexBasis: "60%" }}>
                <h4>{_loc("statisticsGraphs")}</h4>
                <div className='graph'>
                  {
                    lastStatDate > 1
                      ? <ResponsiveContainer minWidth={100} minHeight={100} width="100%" height="100%" margin={{ top: 0, right: 0, bottom: 0, left: 0 }}  >
                        <LineChart
                          data={stats}
                          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        >
                          <XAxis dataKey='date' type="number" scale='time' domain={['auto', 'auto']} interval='preserveStartEnd' tickFormatter={(t) => { return moment.unix(t).format('HH:mm') }} />
                          <YAxis domain={[0, 100]} type='number' scale='linear' tickCount={8} minTickGap={1} />
                          <Tooltip
                            formatter={(value, name, props) => {
                              var dataDesc = "";
                              switch (name) {
                                case "cpuusage":
                                  dataDesc = _loc("cpuUsageIs", `${value} %`);
                                  break;
                                case "ramusage":
                                  dataDesc = _loc("memoryUsageIs", `${value} %`);
                                  break;
                                case "diskusage":
                                  dataDesc = _loc("diskUsageIs", `${value} %`);
                                  break;
                                default:
                                  dataDesc = `Unknown data '${name}' : ${value} %)`;
                                  break;
                              }
                              return [
                                dataDesc
                              ]
                            }}
                            labelFormatter={(v) => { return moment.unix(v).format('DD/MM/YYYY HH:mm:ss') }} />
                          <Line type="linear" dataKey="cpuusage" stroke="#AA0524" legendType='none' dot={false} animationDuration={1000} />
                          <Line type="linear" dataKey="ramusage" stroke="#00455d" legendType='none' dot={false} animationDuration={1000} />
                          <Line type="linear" dataKey="diskusage" stroke="#c8007a" legendType='none' dot={false} animationDuration={1000} />
                        </LineChart>
                      </ResponsiveContainer>
                      : <small>{_loc("noData")}</small>
                  }
                </div>
              </div>

              <div className='stat-block' style={{ flexBasis: "40%" }}>
                <h4>{_loc("notes")}</h4>
                <div className='notes'>
                  <textarea value={notes} onChange={(e) => { this.setState({ notes: e.target.value }) }}></textarea>
                  <div className='buttons'>
                    <button className='bg-success' onClick={() => { this.savePBXAsync() }}><VscRepoPush /> {_loc("save")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='box alarms' style={{ flexBasis: "50%" }}>
            <h4>{_loc("alarms")}</h4>
            <div className='alarms-header'>
              <label>{_loc("alarmshandling")}</label>
              <select className={alarmsEnabled ? 'alarms-enabled' : 'alarms-disabled'} value={alarmsEnabled} onChange={(e) => { this.setAlarmsEnabledAsync(e.currentTarget.value) }}>
                <option value='1'>{_loc("alarmsEnabled")}</option>
                <option value='0'>{_loc("alarmsDisabled")}</option>
              </select>
            </div>
            {
              alarmsEnabled
                ? <Fragment>

                  <PowerTable
                    id="powertable-alarms"
                    saveSettings={false}
                    columns={[
                      new PowerTableColumn('start', null, _loc('alarmStart'), { hideable: false }, (obj, value) => {
                        return moment.unix(obj.start).format("DD/MM/YYYY HH:mm:ss")
                      }),
                      new PowerTableColumn('end', null, _loc('alarmEnd'), {}, (obj, value) => {
                        return <span className={obj.end === 0 ? 'text-danger' : ''} >{obj.end > 0 ? moment.unix(obj.end).format("DD/MM/YYYY HH:mm:ss") : _loc("alarmActive")}</span>
                      }),
                      new PowerTableColumn('duration', (col, obj) => {
                        return obj.end > 0 ? obj.end - obj.start : moment().unix() - obj.start;
                      }, _loc('alarmDuration'), {}, (obj, value) => {
                        return this.formatDuration(value, true)
                      }),
                      new PowerTableColumn('type', 'type', _loc('type'), { hideable: false }),
                      new PowerTableColumn('value', 'value', _loc('value'), {}),
                      new PowerTableColumn('limit', 'limit', _loc('limit'), {}),
                      new PowerTableColumn('cleared', 'cleared', _loc('cleared'), {}, (obj, value) => {
                        return <span className={obj.cleared === 0 ? 'text-danger' : ''}>
                          {
                            obj.cleared > 0
                              ? moment.unix(obj.cleared).format("DD/MM/YYYY HH:mm:ss")
                              : _loc("no")
                          }
                        </span>
                      }),
                      new PowerTableColumn('action', null, '', { hideable: false }, (obj, value) => {
                        return obj.cleared === 0
                          ? <button className='bg-success' onClick={() => { this.clearAlarmAsync(obj.id) }} ><IoConstructSharp /> {_loc("clear")}</button>
                          : null
                      }, false),
                    ]}
                    data={alarms}
                  />

                  <div className='action-buttons'>
                    {
                      alarms.filter(a => a.cleared === 0).length > 0
                        ? <button className='bg-success' onClick={() => { this.clearAllAlarmsAsync() }} ><IoConstructSharp /> {_loc("clearAll")}</button>
                        : null
                    }
                  </div>
                </Fragment>
                : null
            }

          </div>

          <div className='box backups'>
            <h4>{_loc("backupList")}</h4>
            <ul className='backups-list'>
              {
                hasBackupOption
                  ? (ipbx.backups.length > 0
                    ? (ipbx.backups.map((b) => {
                      return <li key={b.filename} className='backup-list-entry'>
                        <div>{b.humanLength}</div>
                        <div>{moment.unix(b.creationDate).format("DD/MM/YYYY")}</div>
                        <div className='link' onClick={() => { this.downloadBackupAsync(b.filename) }}>{b.filename}</div>
                        <div>
                          <button className='bg-danger' onClick={() => {
                            if (window.confirm(_loc("confirmDeleteBackup"))) {
                              this.deleteBackupAsync(b.filename);
                            }
                          }}><IoTrashBinSharp /></button></div>
                      </li>
                    }))
                    : <div className='text-danger'>{_loc("noBackup")}</div>
                  )
                  : <div className='text-danger'>{_loc("noBackupOption")}</div>
              }
            </ul>
          </div>

          <div className='box commands'>
            <h4>{_loc("lastCommands")}</h4>
            {
              supportcommand
                ? <Fragment>
                  <div className='command-form'>
                    <label>{_loc("executeCommand")}</label>
                    <select value={commandToExecute} onChange={(e) => { this.setState({ commandToExecute: e.currentTarget.value }) }}>
                      <option value=''></option>
                      <option value='get_licenses'>{_loc("showLicenses")}</option>
                      <option value='reload_sip'>{_loc("reloadSip")}</option>
                      <option value='sip_show_peers'>{_loc("sipShowPeers")}</option>
                      <option value='sip_show_registry'>{_loc("sipShowRegistry")}</option>
                      <option value='pri_show_spans'>{_loc("priShowSpans")}</option>
                      <option value='sip_show_channels'>{_loc("sipShowChannels")}</option>
                      <option value='core_show_channels'>{_loc("coreShowChannels")}</option>
                      <option value='hangup_all'>{_loc("hangupAll")}</option>
                      <option value='send_backup'>{_loc("createBackup")}</option>
                      <option value='update'>{_loc("updatePBX")}</option>
                      <option value='restart'>{_loc("restartRadmin")}</option>
                      <option value='restart_asterisk'>{_loc("restartAsterisk")}</option>
                      <option value='reboot'>{_loc("restartPBX")}</option>
                    </select>
                    <button className='bg-success' onClick={() => { this.sendCommandAsync() }}>{_loc("execute")}</button>
                  </div>
                  <div className='command-table'>

                    <PowerTable
                      id="powertable-commands"
                      saveSettings={false}
                      columns={
                        [
                          new PowerTableColumn('date', 'date', _loc('date'), { hideable: false }, (obj, value) => { return moment.unix(value).format("DD/MM/YYYY HH:mm:ss") }),
                          new PowerTableColumn('command', 'command', _loc('command'), { hideable: false }, null, false),
                          new PowerTableColumn('status', null, _loc('status'), { hideable: false }, (obj, value) => {
                            return obj.executed
                              ? (
                                obj.code !== null
                                  ? <label><MdOutlineDoneAll className='text-success' /> {_loc("executed")}</label>
                                  : <label><MdDone className='text-success' /> {_loc("inProgress")}</label>
                              )
                              : <label><GiSandsOfTime className='text-warning rotate' /> {_loc("onHold")}</label>
                          }, false),
                          new PowerTableColumn('result', 'result', _loc('result'), { hideable: false }, (obj, value) => {
                            return obj.result !== null && obj.result.trim() !== ""
                              ? <button className='bg-info' onClick={() => { this.setState({ commandToShow: obj }) }}><IoEyeSharp /></button>
                              : null
                          }, false),
                          new PowerTableColumn('action', null, _loc('action'), { hideable: false }, (obj, value) => {
                            return obj.executed === false && obj.code === null
                              ? <button className='bg-danger' onClick={() => { this.deleteCommandAsync(obj.id) }}><MdDeleteForever /></button>
                              : null
                          }, false),
                        ]
                      }
                      data={commands}
                    />

                  </div>
                </Fragment>
                : <small>{_loc("radminVersionMismatch")}</small>
            }

          </div>

          {
            error !== null
              ? <AlertPanel message={error} timeout={3000} top='10px' onTimeout={() => { this.setState({ error: null }) }} type="error" />
              : null
          }
          {
            success !== null
              ? <AlertPanel message={success} timeout={3000} top='10px' onTimeout={() => { this.setState({ success: null }) }} type="success" />
              : null
          }

          {
            commandToShow !== null
              ? <div className='command-result'>
                <h4>{_loc("commandResult")}</h4>
                <small>{_loc("commandCode", commandToShow.code)}</small>
                <textarea readOnly value={commandToShow.result}></textarea>
                <div className='buttons mt-1'>
                  <button className='bg-info' onClick={() => { this.copyCommandResultAsync() }}><VscCopy /> {_loc("copy")}</button>
                  <button className='bg-warning' onClick={() => { this.setState({ commandToShow: null }) }} ><VscChromeClose /> {_loc("close")}</button>
                </div>

              </div>
              : null
          }

        </div>

      </div >
    );
  }
}

export default PbxDetails;
PbxDetails.propTypes = {
  api: PropTypes.object.isRequired,
  serial: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  hasBackupOption: PropTypes.bool.isRequired
}