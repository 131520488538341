import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AlertPanel from '../alertPanel/alertPanel';
import { VscLoading } from 'react-icons/vsc';
import { VscSave, VscTrash, VscAdd } from 'react-icons/vsc';

import { _loc } from '../../i18n/i18n.js';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      users: [],
      newUsers: [],
      error: null,
      success: null,
      currentUser: null,
    }

    this.timerLoading = null;
  }

  componentDidMount() {
    this.timerLoading = setTimeout(async () => {
      const { api } = this.props;
      const currentUser = await api.users.connectedAsync()
      this.setState({
        currentUser
      });
      await this.loadUsersAsync();
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timerLoading);
  }

  async loadUsersAsync() {
    const { api } = this.props;
    const users = await api.users.getUsersAsync();
    this.setState({
      loading: false,
      users: users.map((u) => {
        return {
          ...u,
          password: '',
          password2: '',
          edited: false,
          new: false
        }
      })
    });
  }

  createNewUser() {
    const { newUsers } = this.state;
    this.setState({
      newUsers: [
        ...newUsers,
        {
          tmpid: Date.now(),
          login: '',
          password: '',
          password2: '',
          edited: true
        }
      ]
    })
  }

  async deleteUserAsync(user) {
    const { api } = this.props;
    if (window.confirm(_loc("deleteUserConfirm", user.login))) {
      await api.users.deleteUsersAsync(user.login);
      await this.loadUsersAsync();
    }
  }

  deleteNewUser(tmpid) {
    const { newUsers } = this.state;
    if (window.confirm(_loc("deleteNewUser"))) {
      this.setState({
        newUsers: newUsers.filter(nu => nu.tmpid !== tmpid)
      })
    }
  }

  setUserValue(userid, property, value) {
    const { users } = this.state;
    this.setState({
      users: users.map((u) => {
        if (u.login === userid) {
          return {
            ...u,
            [property]: value,
            edited: true
          }
        }
        return u;
      })
    })
  }

  setNewUserValue(userid, property, value) {
    const { newUsers } = this.state;
    this.setState({
      newUsers: newUsers.map((u) => {
        if (u.tmpid === userid) {
          return {
            ...u,
            [property]: value,
            edited: true
          }
        }
        return u;
      })
    })
  }


  async saveUsersAsync() {
    const { users, newUsers } = this.state;
    const { api } = this.props;

    //first check all passwords match
    for (let u of users) {
      if (u.password !== u.password2) {
        this.setState({
          error: _loc("invalidPassword", u.login)
        })
        return;
      }
    }
    for (let nu of newUsers) {
      if (nu.login.trim() === "") {
        this.setState({
          error: _loc("invalidUsername")
        })
        return;
      }
      if (nu.password !== nu.password2 || nu.password.trim() === "") {
        this.setState({
          error: _loc("invalidPassword", nu.login)
        })
        return;
      }
    }

    for (let u of users) {
      if (u.edited) {
        let res = await api.users.modifyUserAsync(u.login, u.password);
        if (!res) {
          this.setState({
            error: _loc("saveUserFailed", u.login)
          });
          return;
        }
      }
    }
    for (let nu of newUsers) {
      let res = await api.users.createUserAsync(nu.login, nu.password);
      if (!res) {
        this.setState({
          error: _loc("saveUserFailed", nu.login)
        });
        return;
      }
    }
    this.setState({
      success: _loc("userSaved")
    })
  }




  render() {
    const { loading, error, success, users, newUsers, currentUser } = this.state;

    if (loading) {
      return <div className='users'>
        <div className='loading'>
          <VscLoading />
          {_loc("loading")}
        </div>
      </div>
    }

    if (currentUser === null) {
      return null;
    }

    return (
      <div className='users'>
        <h1>{_loc("users")}</h1>

        <table>
          <thead>
            <tr>
              <th>{_loc("username")}</th>
              <th>{_loc("password")}</th>
              <th>{_loc("passwordConfirm")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((u) => {
                return <tr key={u.login}>
                  <td>{u.login}</td>
                  <td>
                    <input type="password"
                      value={u.password}
                      onChange={(e) => { this.setUserValue(u.login, "password", e.currentTarget.value) }}
                      placeholder={_loc("enterPassword")}
                      style={{
                        borderColor: u.password !== u.password2 ? 'var(--ipc-red)' : null
                      }}
                    />
                  </td>
                  <td>
                    <input type="password"
                      value={u.password2}
                      onChange={(e) => { this.setUserValue(u.login, "password2", e.currentTarget.value) }}
                      placeholder={_loc("validatePassword")}
                      style={{
                        borderColor: u.password !== u.password2 ? 'var(--ipc-red)' : null
                      }}
                    />
                  </td>
                  <td >
                    {
                      currentUser.login !== u.login
                        ? <button className='bg-danger' onClick={() => { this.deleteUserAsync(u) }}><VscTrash /></button>
                        : null
                    }

                  </td>
                </tr>
              })
            }
            {
              newUsers.map((nu) => {
                return <tr key={nu.tmpid}>
                  <td>
                    <input type='text'
                      value={nu.login}
                      placeholder={_loc("enterUsername")}
                      style={{
                        borderColor: nu.login.trim() === '' ? 'var(--ipc-red)' : null
                      }}
                      onChange={(e) => { this.setNewUserValue(nu.tmpid, "login", e.currentTarget.value) }}
                    />
                  </td>
                  <td><input type='password' value={nu.password}
                    style={{
                      borderColor: nu.password !== nu.password2 ? 'var(--ipc-red)' : null
                    }}
                    onChange={(e) => { this.setNewUserValue(nu.tmpid, "password", e.currentTarget.value) }}
                    placeholder={_loc("enterPassword")} /></td>
                  <td><input type='password' value={nu.password2}
                    style={{
                      borderColor: nu.password !== nu.password2 ? 'var(--ipc-red)' : null
                    }}
                    onChange={(e) => { this.setNewUserValue(nu.tmpid, "password2", e.currentTarget.value) }}
                    placeholder={_loc("validatePassword")} /></td>
                  <td className='buttons'>
                    <button className='bg-danger' onClick={() => { this.deleteNewUser(nu.tmpid) }}><VscTrash /></button>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>

        <div className='buttons'>
          <button className='bg-info' onClick={() => { this.createNewUser() }}>
            <VscAdd /> {_loc("createUser")}
          </button>
          <button className='bg-success' onClick={() => { this.saveUsersAsync() }}>
            <VscSave /> {_loc("save")}
          </button>
        </div>

        {
          error !== null
            ? <AlertPanel message={error} timeout={3000} top='10px' onTimeout={() => { this.setState({ error: null }) }} type="error" />
            : null
        }
        {
          success !== null
            ? <AlertPanel message={success} timeout={3000} top='10px' onTimeout={() => { this.setState({ success: null }) }} type="success" />
            : null
        }


      </div>
    );
  }
}

export default Users;
Users.propTypes = {
  api: PropTypes.object.isRequired,
}