var _baseUri = "";
let _onAuthError = null;

/**
 * 
 * @param {string} apicall the api to call, for example /user/login/ You must end it with /, if not the api will try to add itself.
 * @param {string} queryData  the query data to send, as an object. Exemple: {login: "dldldl", password="dssdf"} . Will be converted to query string
 */
async function _getAsync(apicall, queryData = {}) {
  try {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      credentials: 'include'
    };

    var querySearchParams = new URLSearchParams();
    for (var qd in queryData) {
      querySearchParams.append(qd, queryData[qd]);
    }
    const queryString = querySearchParams.toString() !== "" ? `?${querySearchParams.toString()}` : "";

    /*if (apicall[apicall.length - 1] !== "/") {
      apicall = apicall + "/";
    }*/

    const uri = `${_baseUri}${apicall}${queryString}`;
    var response = await fetch(uri, requestOptions);
    if (response.ok) {
      return await response.json();
    }
    else {
      console.error(`Got status ${response.status} [${response.statusText}] when calling ${apicall}`);
      if (response.status === 403 && _onAuthError) {
        _onAuthError();
      }
      return null;
    }
  }
  catch (e) {
    console.error(e);
    return null;
  }
}


/**
 * 
 * @param {string} apicall the api to call, for example /user/login/ You must end it with /, if not the api will try to add itself.
 * @param {string} queryData  the query data to send, as an object. Exemple: {login: "dldldl", password="dssdf"} . Will be converted to query string
 */
async function _getBlobAsync(apicall, queryData = {}) {
  try {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      credentials: 'include'
    };

    var querySearchParams = new URLSearchParams();
    for (var qd in queryData) {
      querySearchParams.append(qd, queryData[qd]);
    }
    const queryString = querySearchParams.toString() !== "" ? `?${querySearchParams.toString()}` : "";

    /*if (apicall[apicall.length - 1] !== "/") {
      apicall = apicall + "/";
    }*/

    const uri = `${_baseUri}${apicall}${queryString}`;
    var response = await fetch(uri, requestOptions);
    if (response.ok) {
      return await response.blob();
    }
    else {
      console.error(`Got status ${response.status} [${response.statusText}] when calling ${apicall}`);
      if (response.status === 403 && _onAuthError) {
        _onAuthError();
      }
      return null;
    }
  }
  catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * 
 * @param {string} apicall the api to call, for example /user/login/ You must end it with /, if not the api will try to add itself.
 * @param {string} queryData  the query data to send, as an object. Exemple: {login: "dldldl", password="dssdf"} . Will be converted to query string
 * @param {array} data  the data to send using post, for example: { login: "toto", password: "tata" }
 */
async function _postAsync(apicall, queryData = {}, data = {}) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var querySearchParams = new URLSearchParams();
    for (var qd in queryData) {
      querySearchParams.append(qd, queryData[qd]);
    }
    const queryString = querySearchParams.toString() !== "" ? `?${querySearchParams.toString()}` : "";

    var postData = new URLSearchParams();
    for (var d in data) {
      postData.append(d, data[d]);
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: postData,
      redirect: 'follow',
      credentials: 'include'
    };

    /*if (apicall[apicall.length - 1] !== "/") {
  apicall = apicall + "/";
}*/

    const uri = `${_baseUri}${apicall}${queryString}`;
    var response = await fetch(uri, requestOptions);
    if (response.ok) {
      return await response.json();
    }
    else {
      console.error(`Got status ${response.status} [${response.statusText}] when calling ${apicall}`);
      if (response.status === 403 && _onAuthError) {
        _onAuthError();
      }
      return null;
    }

  }
  catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * 
 * @param {string} apicall the api to call, for example /user/login/ You must end it with /, if not the api will try to add itself.
 * @param {string} queryData  the query data to send, as an object. Exemple: {login: "dldldl", password="dssdf"} . Will be converted to query string
 * @param {array} data  the data to send using post, for example: { login: "toto", password: "tata" }
 */
async function _putAsync(apicall, queryData = {}, data = {}) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var querySearchParams = new URLSearchParams();
    for (var qd in queryData) {
      querySearchParams.append(qd, queryData[qd]);
    }
    const queryString = querySearchParams.toString() !== "" ? `?${querySearchParams.toString()}` : "";

    var postData = new URLSearchParams();
    for (var d in data) {
      postData.append(d, data[d]);
    }

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: postData,
      redirect: 'follow',
      credentials: 'include'
    };

    /*if (apicall[apicall.length - 1] !== "/") {
   apicall = apicall + "/";
 }*/

    const uri = `${_baseUri}${apicall}${queryString}`;
    var response = await fetch(uri, requestOptions);
    if (response.ok) {
      return await response.json();
    }
    else {
      console.error(`Got status ${response.status} [${response.statusText}] when calling ${apicall}`);
      if (response.status === 403 && _onAuthError) {
        _onAuthError();
      }
      return null;
    }
  }
  catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * 
 * @param {string} apicall the api to call, for example /user/login/ You must end it with /, if not the api will try to add itself.
 * @param {string} queryData  the query data to send, as an object. Exemple: {login: "dldldl", password="dssdf"} . Will be converted to query string
 */
async function _deleteAsync(apicall, queryData = {}) {
  try {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      credentials: 'include'
    };

    var querySearchParams = new URLSearchParams();
    for (var qd in queryData) {
      querySearchParams.append(qd, queryData[qd]);
    }
    const queryString = querySearchParams.toString() !== "" ? `?${querySearchParams.toString()}` : "";

    /*if (apicall[apicall.length - 1] !== "/") {
   apicall = apicall + "/";
 }*/

    const uri = `${_baseUri}${apicall}${queryString}`;
    var response = await fetch(uri, requestOptions);
    if (response.ok) {
      return await response.json();
    }
    else {
      console.error(`Got status ${response.status} [${response.statusText}] when calling ${apicall}`);
      if (response.status === 403 && _onAuthError) {
        _onAuthError();
      }
      return null;
    }
  }
  catch (e) {
    console.error(e);
    return null;
  }
}








/**
 * set the base URI to call radmin server api
 * 
 * @param {string} base set the base uri, for example https://radmin.ipconnect.fr/api . DO NOT add the extra / at the end !
 */
function initApi(baseURI, onAuthError) {
  _baseUri = baseURI;
  _onAuthError = onAuthError;
  console.log(`API will use base uri: ${_baseUri}`);
}


/**
 * Check if this server has a valid license.
 * @returns 
 */
async function hasLicenseAsync(code) {
  const res = await _getAsync("/haslicense", { code });
  if (res !== null && res.result === "success") {
    return res.data;
  }
  console.error("Error", res);
  return false;
}



const utils = {
  diskFreeSpaceAsync: async () => {
    const res = await _getAsync("/diskusage/", {});
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },
}


/**
 * Handle all the USER api access, including Login and logout
 */
const users = {
  connectedAsync: async () => {
    const res = await _getAsync("/connected/", {});
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  loginAsync: async (login, password) => {
    const res = await _getAsync("/user/login/", {
      login,
      password
    });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  logoutAsync: async () => {
    const res = await _getAsync("/user/logout/");
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  getUsersAsync: async () => {
    const res = await _getAsync("/users/");
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  createUserAsync: async (login, password) => {
    const res = await _postAsync("/user/", {}, {
      login,
      password
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  modifyUserAsync: async (login, password) => {
    const res = await _putAsync("/user/", {
      login
    }, {
      password
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  deleteUsersAsync: async (login) => {
    const res = await _deleteAsync("/user/", { login });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

}


/**
 * Handle all operation on IPBX objects
 */
const ipbxs = {
  getIPBXsAsync: async () => {
    const res = await _getAsync("/ipbxs/");
    if (res && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  getIPBXAsync: async (serial) => {
    const res = await _getAsync("/ipbx/", { serial: serial });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  getIPBXBackupAsync: async (serial, backup) => {
    const blob = await _getBlobAsync("/ipbx/backup", { serial: serial, backup: backup });
    if (blob !== null) {
      return blob;
    }
    console.error("Error", blob);
    return null;
  },

  createIPBXAsync: async (serial, name, notes) => {
    const res = await _postAsync("/ipbx/", {}, {
      serial,
      name,
      notes
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  modifyIPBXAsync: async (serial, name, notes, alarmsEnabled) => {
    const res = await _putAsync("/ipbx/", { serial }, {
      name,
      notes,
      alarmsEnabled
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  deleteIPBXAsync: async (serial) => {
    const res = await _deleteAsync("/ipbx/", { serial });
    if (res !== null && res.result === "success") {
      return true
    }
    console.error("Error", res);
    return false;
  },

  cleanIPBXsListAsync: async (days) => {
    const res = await _getAsync("/ipbx/cleanlist", { days });
    if (res !== null && res.result === "success") {
      return res.pbxs;
    }
    console.error("Error", res);
    return null;
  },

  cleanIPBXsAsync: async (days) => {
    const res = await _deleteAsync("/ipbx/clean", { days });
    if (res !== null && res.result === "success") {
      return res.deleted;
    }
    console.error("Error", res);
    return false;
  },

  deleteIPBXBackupAsync: async (serial, backup) => {
    const res = await _deleteAsync("/ipbx/backup", { serial, backup });
    if (res !== null && res.result === "success") {
      return true
    }
    console.error("Error", res);
    return false;
  }
}


/**
 * Handle all setting management
 */
const settings = {
  getSettingsAsync: async () => {
    const res = await _getAsync("/settings/");
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  setSettingsAsync: async (settings) => {
    const res = await _postAsync("/settings/", {}, {
      settings: JSON.stringify(settings)
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  testMailSettingsAsync: async (to) => {
    const res = await _postAsync("/testmail/", {}, {
      to
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return res.error;
  },
}


/**
 * Handle all the commands management
 */
const commands = {
  getCommandsAsync: async (serial, count = 10) => {
    const res = await _getAsync("/commands/", { serial, count });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  getNextCommandAsync: async (serial) => {
    const res = await _getAsync("/commands/next/", { serial });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  createCommandAsync: async (serial, command) => {
    const res = await _postAsync("/command/", {}, {
      serial,
      command
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  deleteCommandAsync: async (serial, id) => {
    const res = await _deleteAsync("/command/", { serial, id });
    if (res !== null && res.result === "success") {
      return true
    }
    console.error("Error", res);
    return false;
  },

  setCommandResultAsync: async (serial, commandId, code, result) => {
    const res = await _putAsync("/command/", { serial, id: commandId }, {
      code,
      result
    });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  }
}


/**
 * Manage statistics api calls
 */
const stats = {
  getStatsAsync: async (serial, from, to) => {
    const res = await _getAsync("/stats/", { serial, from, to });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  getLastStatsAsync: async (serial) => {
    const res = await _getAsync("/stats/last/", { serial });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

}



const allowedIPs = {
  getAllowedIPsAsync: async () => {
    const res = await _getAsync("/allowedips/");
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  addAllowedIPAsync: async (ip) => {
    const res = await _postAsync("/allowedip/", {}, { ip });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  removeAllowedIPAsync: async (ip) => {
    const res = await _deleteAsync("/allowedip/", { ip });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  }
}


/**
 * Manage alarms api calls
 */
const alarms = {
  getAlarmsAsync: async (serial) => {
    const res = await _getAsync("/alarms/", { serial });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  clearAlarmAsync: async (id) => {
    const res = await _postAsync("/clearalarm/", {}, { id });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  clearAllPBXAlarmsAsync: async (serial) => {
    const res = await _postAsync("/clearallalarms/", {}, { serial });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

  clearAllAlarmsAsync: async () => {
    const res = await _postAsync("/clearallalarms/", {}, {});
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },

}



/**
 * Manage news
 */
const news = {
  getActiveNews: async (lang) => {
    const res = await _getAsync("/news/active/", { lang });
    if (res !== null && res.result === "success") {
      return res.data;
    }
    console.error("Error", res);
    return null;
  },

  discardNews: async (id) => {
    const res = await _postAsync("/news/discard", {}, { id });
    if (res !== null && res.result === "success") {
      return true;
    }
    console.error("Error", res);
    return false;
  },
}


const api = {
  initApi,
  hasLicenseAsync,
  utils,
  users,
  ipbxs,
  settings,
  commands,
  stats,
  allowedIPs,
  alarms,
  news
}
export default api;